import { Component, OnInit } from '@angular/core';

import { LangService } from '../../core/modules/translation';


@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.scss'],
  standalone: false,
})
export class LanguageComponent implements OnInit {

  languages = this.langService.languages;

  constructor(public langService: LangService) { }

  ngOnInit() {
  }

  onClick(language: string) {

    // make sure the lang attribute is set on the webpage according to the current application settings so
    // Google translate detects the source language correctly
    document.documentElement.setAttribute('lang', language);

    this.langService.setLanguage(language);
  }

}
