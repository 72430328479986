import * as i0 from '@angular/core';
import { Directive, Component, ViewEncapsulation, ChangeDetectionStrategy, Attribute, Input, EventEmitter, ViewChild, Output, ContentChild, ContentChildren, Optional, NgModule } from '@angular/core';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
const _c0 = [[["", "pIcon", ""], ["", "p-icon", ""], ["", "pSvg", ""], ["", "p-svg", ""], ["", "pImage", ""], ["", "p-image", ""]], "*"];
const _c1 = ["[pIcon], [p-icon], [pSvg], [p-svg], [pImage], [p-image]", "*"];
const _c2 = ["will-change", ""];
const _c3 = ["inputElem"];
const _c4 = ["stateElem"];
const _c5 = [[["ngx-p-indeterminate"], ["p-indeterminate"], ["ngx-p-hover"], ["p-hover"], ["ngx-p-toggle"], ["p-toggle"]], [["", "pIcon", ""], ["", "p-icon", ""], ["", "pSvg", ""], ["", "p-svg", ""], ["", "pImage", ""], ["", "p-image", ""]], "*"];
const _c6 = ["ngx-p-indeterminate, p-indeterminate, ngx-p-hover, p-hover, ngx-p-toggle, p-toggle", "[pIcon], [p-icon], [pSvg], [p-svg], [pImage], [p-image]", "*"];
function NgxPrettyCheckboxComponent_div_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 4, 1);
    i0.ɵɵprojection(2, 1);
    i0.ɵɵelementStart(3, "label");
    i0.ɵɵprojection(4, 2);
    i0.ɵɵelementEnd()();
  }
}
const _c7 = a0 => [a0];
function NgxPrettyCheckboxWillChangeComponent_div_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 3);
    i0.ɵɵprojection(1, 1);
    i0.ɵɵelementStart(2, "label");
    i0.ɵɵprojection(3, 2);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(1, _c7, ctx_r2.color ? "p-" + ctx_r2.color + (ctx_r2.outline ? "-o" : "") : ""));
  }
}
const _c8 = [[["ngx-p-hover"], ["p-hover"], ["ngx-p-toggle"], ["p-toggle"]], [["", "pIcon", ""], ["", "p-icon", ""], ["", "pSvg", ""], ["", "p-svg", ""], ["", "pImage", ""], ["", "p-image", ""]], "*"];
const _c9 = ["ngx-p-hover, p-hover, ngx-p-toggle, p-toggle", "[pIcon], [p-icon], [pSvg], [p-svg], [pImage], [p-image]", "*"];
function NgxPrettyRadioComponent_div_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 4, 1);
    i0.ɵɵprojection(2, 1);
    i0.ɵɵelementStart(3, "label");
    i0.ɵɵprojection(4, 2);
    i0.ɵɵelementEnd()();
  }
}
function NgxPrettyRadioWillChangeComponent_div_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 3);
    i0.ɵɵprojection(1, 1);
    i0.ɵɵelementStart(2, "label");
    i0.ɵɵprojection(3, 2);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(1, _c7, ctx_r2.color ? "p-" + ctx_r2.color + (ctx_r2.outline ? "-o" : "") : ""));
  }
}
function getColorClassName(color, outline) {
  if (!color) {
    return null;
  }
  return 'p-' + color + (outline ? '-o' : '');
}
function strToBoolean(str = '') {
  if (typeof str === 'boolean') {
    return str;
  }
  const regex = /^\s*(true|1|on|enable)\s*$/i;
  return regex.test(str);
}
class NgxPrettyIconDirective {
  constructor(elemRef) {
    this.elemRef = elemRef;
    this.elemRef.nativeElement.classList.add('icon');
  }
  static {
    this.ɵfac = function NgxPrettyIconDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NgxPrettyIconDirective)(i0.ɵɵdirectiveInject(i0.ElementRef));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: NgxPrettyIconDirective,
      selectors: [["", "pIcon", ""], ["", "p-icon", ""]]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxPrettyIconDirective, [{
    type: Directive,
    args: [{
      selector: '[pIcon], [p-icon]',
      standalone: true
    }]
  }], () => [{
    type: i0.ElementRef
  }], null);
})();
var PrettyCheckBoxToggleType;
(function (PrettyCheckBoxToggleType) {
  PrettyCheckBoxToggleType["On"] = "on";
  PrettyCheckBoxToggleType["Off"] = "off";
})(PrettyCheckBoxToggleType || (PrettyCheckBoxToggleType = {}));
var PrettyCheckboxStroke;
(function (PrettyCheckboxStroke) {
  // Accept Null
  PrettyCheckboxStroke["Fill"] = "fill";
  PrettyCheckboxStroke["Thick"] = "thick";
  PrettyCheckboxStroke["Slim"] = "slim"; // Switch
})(PrettyCheckboxStroke || (PrettyCheckboxStroke = {}));
var PrettyCheckboxShape;
(function (PrettyCheckboxShape) {
  // Accept Null
  PrettyCheckboxShape["Curve"] = "curve";
  PrettyCheckboxShape["Round"] = "round";
})(PrettyCheckboxShape || (PrettyCheckboxShape = {}));
var PrettyCheckboxColor;
(function (PrettyCheckboxColor) {
  PrettyCheckboxColor["Primary"] = "primary";
  PrettyCheckboxColor["Success"] = "success";
  PrettyCheckboxColor["Info"] = "info";
  PrettyCheckboxColor["Warning"] = "warning";
  PrettyCheckboxColor["Danger"] = "danger";
})(PrettyCheckboxColor || (PrettyCheckboxColor = {}));
var PrettyCheckboxAnimation;
(function (PrettyCheckboxAnimation) {
  PrettyCheckboxAnimation["Smooth"] = "smooth";
  PrettyCheckboxAnimation["Jelly"] = "jelly";
  PrettyCheckboxAnimation["Tada"] = "tada";
  PrettyCheckboxAnimation["Rotate"] = "rotate";
  PrettyCheckboxAnimation["Pulse"] = "pulse";
})(PrettyCheckboxAnimation || (PrettyCheckboxAnimation = {}));
class NgxPrettyHoverComponent {
  constructor(el, renderer, _outline = false, _color) {
    this.el = el;
    this.renderer = renderer;
    this._outline = _outline;
    this._color = _color;
  }
  ngAfterViewInit() {
    if (this._color) {
      this.renderer.addClass(this.el.nativeElement, `p-${this._color}${this._outline ? '-o' : ''}`);
    }
  }
  static {
    this.ɵfac = function NgxPrettyHoverComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NgxPrettyHoverComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵinjectAttribute('outline'), i0.ɵɵinjectAttribute('color'));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: NgxPrettyHoverComponent,
      selectors: [["ngx-p-hover", 3, "will-change", ""], ["p-hover", 3, "will-change", ""]],
      hostAttrs: [1, "state", "p-is-hover"],
      ngContentSelectors: _c1,
      decls: 3,
      vars: 0,
      template: function NgxPrettyHoverComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef(_c0);
          i0.ɵɵprojection(0);
          i0.ɵɵelementStart(1, "label");
          i0.ɵɵprojection(2, 1);
          i0.ɵɵelementEnd();
        }
      },
      dependencies: [CommonModule],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxPrettyHoverComponent, [{
    type: Component,
    args: [{
      selector: 'ngx-p-hover:not([will-change]), p-hover:not([will-change])',
      standalone: true,
      imports: [CommonModule],
      encapsulation: ViewEncapsulation.None,
      changeDetection: ChangeDetectionStrategy.OnPush,
      host: {
        class: `state p-is-hover`
      },
      template: `
    <ng-content select="[pIcon], [p-icon], [pSvg], [p-svg], [pImage], [p-image]"></ng-content>
    <label><ng-content></ng-content></label>
  `
    }]
  }], () => [{
    type: i0.ElementRef
  }, {
    type: i0.Renderer2
  }, {
    type: undefined,
    decorators: [{
      type: Attribute,
      args: ['outline']
    }]
  }, {
    type: PrettyCheckboxColor,
    decorators: [{
      type: Attribute,
      args: ['color']
    }]
  }], null);
})();
class NgxPrettyHoverWillChangeComponent {
  constructor(el, renderer) {
    this.el = el;
    this.renderer = renderer;
    this.outline = false;
  }
  ngOnChanges(changes) {
    if (changes.color || changes.outline) {
      const oldClass = getColorClassName(changes.color ? changes.color.previousValue : this.color, changes.outline ? changes.outline.previousValue : null);
      const newClass = getColorClassName(changes.color ? changes.color.currentValue : this.color, changes.outline ? changes.outline.currentValue : null);
      this.renderer.removeClass(this.el.nativeElement, oldClass);
      this.renderer.addClass(this.el.nativeElement, newClass);
    }
  }
  static {
    this.ɵfac = function NgxPrettyHoverWillChangeComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NgxPrettyHoverWillChangeComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: NgxPrettyHoverWillChangeComponent,
      selectors: [["ngx-p-hover", "will-change", ""], ["p-hover", "will-change", ""]],
      hostAttrs: [1, "state", "p-is-hover"],
      inputs: {
        color: "color",
        outline: "outline"
      },
      features: [i0.ɵɵNgOnChangesFeature],
      attrs: _c2,
      ngContentSelectors: _c1,
      decls: 3,
      vars: 0,
      template: function NgxPrettyHoverWillChangeComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef(_c0);
          i0.ɵɵprojection(0);
          i0.ɵɵelementStart(1, "label");
          i0.ɵɵprojection(2, 1);
          i0.ɵɵelementEnd();
        }
      },
      dependencies: [CommonModule],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxPrettyHoverWillChangeComponent, [{
    type: Component,
    args: [{
      selector: 'ngx-p-hover[will-change], p-hover[will-change]',
      standalone: true,
      imports: [CommonModule],
      encapsulation: ViewEncapsulation.None,
      changeDetection: ChangeDetectionStrategy.OnPush,
      host: {
        class: `state p-is-hover`
      },
      template: `
    <ng-content select="[pIcon], [p-icon], [pSvg], [p-svg], [pImage], [p-image]"></ng-content>
    <label><ng-content></ng-content></label>
  `
    }]
  }], () => [{
    type: i0.ElementRef
  }, {
    type: i0.Renderer2
  }], {
    color: [{
      type: Input
    }],
    outline: [{
      type: Input
    }]
  });
})();
class NgxPrettyIndeterminateComponent {
  constructor(el, renderer, _outline = false, _color) {
    this.el = el;
    this.renderer = renderer;
    this._outline = _outline;
    this._color = _color;
  }
  ngAfterViewInit() {
    if (this._color) {
      this.renderer.addClass(this.el.nativeElement, `p-${this._color}${this._outline ? '-o' : ''}`);
    }
  }
  static {
    this.ɵfac = function NgxPrettyIndeterminateComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NgxPrettyIndeterminateComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵinjectAttribute('outline'), i0.ɵɵinjectAttribute('color'));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: NgxPrettyIndeterminateComponent,
      selectors: [["ngx-p-indeterminate", 3, "will-change", ""], ["p-indeterminate", 3, "will-change", ""]],
      hostAttrs: [1, "state", "p-is-indeterminate"],
      ngContentSelectors: _c1,
      decls: 3,
      vars: 0,
      template: function NgxPrettyIndeterminateComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef(_c0);
          i0.ɵɵprojection(0);
          i0.ɵɵelementStart(1, "label");
          i0.ɵɵprojection(2, 1);
          i0.ɵɵelementEnd();
        }
      },
      dependencies: [CommonModule],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxPrettyIndeterminateComponent, [{
    type: Component,
    args: [{
      selector: 'ngx-p-indeterminate:not([will-change]), p-indeterminate:not([will-change])',
      standalone: true,
      imports: [CommonModule],
      encapsulation: ViewEncapsulation.None,
      changeDetection: ChangeDetectionStrategy.OnPush,
      host: {
        class: `state p-is-indeterminate`
      },
      template: `
    <ng-content select="[pIcon], [p-icon], [pSvg], [p-svg], [pImage], [p-image]"></ng-content>
    <label><ng-content></ng-content></label>
  `
    }]
  }], () => [{
    type: i0.ElementRef
  }, {
    type: i0.Renderer2
  }, {
    type: undefined,
    decorators: [{
      type: Attribute,
      args: ['outline']
    }]
  }, {
    type: PrettyCheckboxColor,
    decorators: [{
      type: Attribute,
      args: ['color']
    }]
  }], null);
})();
class NgxPrettyIndeterminateWillChangeComponent {
  constructor(el, renderer) {
    this.el = el;
    this.renderer = renderer;
    this.outline = false;
  }
  ngOnChanges(changes) {
    if (changes.color || changes.outline) {
      const oldClass = getColorClassName(changes.color ? changes.color.previousValue : this.color, changes.outline ? changes.outline.previousValue : null);
      const newClass = getColorClassName(changes.color ? changes.color.currentValue : this.color, changes.outline ? changes.outline.currentValue : null);
      this.renderer.removeClass(this.el.nativeElement, oldClass);
      this.renderer.addClass(this.el.nativeElement, newClass);
    }
  }
  static {
    this.ɵfac = function NgxPrettyIndeterminateWillChangeComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NgxPrettyIndeterminateWillChangeComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: NgxPrettyIndeterminateWillChangeComponent,
      selectors: [["ngx-p-indeterminate", "will-change", ""], ["p-indeterminate", "will-change", ""]],
      hostAttrs: [1, "state", "p-is-indeterminate"],
      inputs: {
        color: "color",
        outline: "outline"
      },
      features: [i0.ɵɵNgOnChangesFeature],
      attrs: _c2,
      ngContentSelectors: _c1,
      decls: 3,
      vars: 0,
      template: function NgxPrettyIndeterminateWillChangeComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef(_c0);
          i0.ɵɵprojection(0);
          i0.ɵɵelementStart(1, "label");
          i0.ɵɵprojection(2, 1);
          i0.ɵɵelementEnd();
        }
      },
      dependencies: [CommonModule],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxPrettyIndeterminateWillChangeComponent, [{
    type: Component,
    args: [{
      selector: 'ngx-p-indeterminate[will-change], p-indeterminate[will-change]',
      standalone: true,
      imports: [CommonModule],
      encapsulation: ViewEncapsulation.None,
      changeDetection: ChangeDetectionStrategy.OnPush,
      host: {
        class: `state p-is-indeterminate`
      },
      template: `
    <ng-content select="[pIcon], [p-icon], [pSvg], [p-svg], [pImage], [p-image]"></ng-content>
    <label><ng-content></ng-content></label>
  `
    }]
  }], () => [{
    type: i0.ElementRef
  }, {
    type: i0.Renderer2
  }], {
    color: [{
      type: Input
    }],
    outline: [{
      type: Input
    }]
  });
})();
class NgxPrettyToggleComponent {
  constructor(el, renderer, _outline = false, _color, _type) {
    this.el = el;
    this.renderer = renderer;
    this._outline = _outline;
    this._color = _color;
    this._type = _type;
  }
  ngAfterViewInit() {
    if (this._color) {
      this.renderer.addClass(this.el.nativeElement, `p-${this._color}${this._outline ? '-o' : ''}`);
    }
    this.renderer.addClass(this.el.nativeElement, `p-${this._type}`);
  }
  static {
    this.ɵfac = function NgxPrettyToggleComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NgxPrettyToggleComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵinjectAttribute('outline'), i0.ɵɵinjectAttribute('color'), i0.ɵɵinjectAttribute('type'));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: NgxPrettyToggleComponent,
      selectors: [["ngx-p-toggle", 3, "will-change", ""], ["p-toggle", 3, "will-change", ""]],
      hostAttrs: [1, "state"],
      ngContentSelectors: _c1,
      decls: 3,
      vars: 0,
      template: function NgxPrettyToggleComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef(_c0);
          i0.ɵɵprojection(0);
          i0.ɵɵelementStart(1, "label");
          i0.ɵɵprojection(2, 1);
          i0.ɵɵelementEnd();
        }
      },
      dependencies: [CommonModule],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxPrettyToggleComponent, [{
    type: Component,
    args: [{
      selector: 'ngx-p-toggle:not([will-change]), p-toggle:not([will-change])',
      standalone: true,
      imports: [CommonModule],
      encapsulation: ViewEncapsulation.None,
      changeDetection: ChangeDetectionStrategy.OnPush,
      host: {
        class: 'state'
      },
      template: `
    <ng-content select="[pIcon], [p-icon], [pSvg], [p-svg], [pImage], [p-image]"></ng-content>
    <label><ng-content></ng-content></label>
  `
    }]
  }], () => [{
    type: i0.ElementRef
  }, {
    type: i0.Renderer2
  }, {
    type: undefined,
    decorators: [{
      type: Attribute,
      args: ['outline']
    }]
  }, {
    type: PrettyCheckboxColor,
    decorators: [{
      type: Attribute,
      args: ['color']
    }]
  }, {
    type: PrettyCheckBoxToggleType,
    decorators: [{
      type: Attribute,
      args: ['type']
    }]
  }], null);
})();
class NgxPrettyToggleWillChangeComponent {
  get isToggleOn() {
    return this.type === PrettyCheckBoxToggleType.On;
  }
  get isToggleOff() {
    return this.type === PrettyCheckBoxToggleType.Off;
  }
  constructor(el, renderer) {
    this.el = el;
    this.renderer = renderer;
    this.outline = false;
  }
  ngOnChanges(changes) {
    if (changes.color || changes.outline) {
      const oldClass = getColorClassName(changes.color ? changes.color.previousValue : this.color, changes.outline ? changes.outline.previousValue : null);
      const newClass = getColorClassName(changes.color ? changes.color.currentValue : this.color, changes.outline ? changes.outline.currentValue : null);
      this.renderer.removeClass(this.el.nativeElement, oldClass);
      this.renderer.addClass(this.el.nativeElement, newClass);
    }
  }
  static {
    this.ɵfac = function NgxPrettyToggleWillChangeComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NgxPrettyToggleWillChangeComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: NgxPrettyToggleWillChangeComponent,
      selectors: [["ngx-p-toggle", "will-change", ""], ["p-toggle", "will-change", ""]],
      hostAttrs: [1, "state"],
      hostVars: 4,
      hostBindings: function NgxPrettyToggleWillChangeComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassProp("p-on", ctx.isToggleOn)("p-off", ctx.isToggleOff);
        }
      },
      inputs: {
        type: "type",
        color: "color",
        outline: "outline"
      },
      features: [i0.ɵɵNgOnChangesFeature],
      attrs: _c2,
      ngContentSelectors: _c1,
      decls: 3,
      vars: 0,
      template: function NgxPrettyToggleWillChangeComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef(_c0);
          i0.ɵɵprojection(0);
          i0.ɵɵelementStart(1, "label");
          i0.ɵɵprojection(2, 1);
          i0.ɵɵelementEnd();
        }
      },
      dependencies: [CommonModule],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxPrettyToggleWillChangeComponent, [{
    type: Component,
    args: [{
      selector: 'ngx-p-toggle[will-change], p-toggle[will-change]',
      standalone: true,
      imports: [CommonModule],
      encapsulation: ViewEncapsulation.None,
      changeDetection: ChangeDetectionStrategy.OnPush,
      host: {
        'class': 'state',
        '[class.p-on]': 'isToggleOn',
        '[class.p-off]': 'isToggleOff'
      },
      template: `
    <ng-content select="[pIcon], [p-icon], [pSvg], [p-svg], [pImage], [p-image]"></ng-content>
    <label><ng-content></ng-content></label>
  `
    }]
  }], () => [{
    type: i0.ElementRef
  }, {
    type: i0.Renderer2
  }], {
    type: [{
      type: Input
    }],
    color: [{
      type: Input
    }],
    outline: [{
      type: Input
    }]
  });
})();
class NgxPrettySvgDirective {
  constructor(elemRef) {
    this.elemRef = elemRef;
  }
  ngAfterViewInit() {
    this.elemRef.nativeElement.classList.add('svg');
  }
  static {
    this.ɵfac = function NgxPrettySvgDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NgxPrettySvgDirective)(i0.ɵɵdirectiveInject(i0.ElementRef));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: NgxPrettySvgDirective,
      selectors: [["", "pSvg", ""], ["", "p-svg", ""]]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxPrettySvgDirective, [{
    type: Directive,
    args: [{
      selector: '[pSvg], [p-svg]',
      standalone: true
    }]
  }], () => [{
    type: i0.ElementRef
  }], null);
})();
class NgxPrettyImageDirective {
  constructor(elemRef) {
    this.elemRef = elemRef;
    this.elemRef.nativeElement.classList.add('image');
  }
  static {
    this.ɵfac = function NgxPrettyImageDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NgxPrettyImageDirective)(i0.ɵɵdirectiveInject(i0.ElementRef));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: NgxPrettyImageDirective,
      selectors: [["", "pImage", ""], ["", "p-image", ""]]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxPrettyImageDirective, [{
    type: Directive,
    args: [{
      selector: '[pImage], [p-image]',
      standalone: true
    }]
  }], () => [{
    type: i0.ElementRef
  }], null);
})();
class NgxPrettyCheckboxComponent {
  get _isToggle() {
    return this._toggleComps.length === 2 || this._toggleWillChangeComps.length === 2;
  }
  constructor(renderer, el, cd, _stroke, _shape, _animation, _isSwitch = false, _bigger = false, _enableFocus = false, _plain = false, _outline = false, _color) {
    this.renderer = renderer;
    this.el = el;
    this.cd = cd;
    this._stroke = _stroke;
    this._shape = _shape;
    this._animation = _animation;
    this._isSwitch = _isSwitch;
    this._bigger = _bigger;
    this._enableFocus = _enableFocus;
    this._plain = _plain;
    this._outline = _outline;
    this._color = _color;
    this.checked = false;
    this.disabled = false;
    this.lock = false;
    this.stateless = false;
    this.change = new EventEmitter();
    this._isSwitch = strToBoolean(this._isSwitch);
    this._bigger = strToBoolean(this._bigger);
    this._enableFocus = strToBoolean(this._enableFocus);
    this._plain = strToBoolean(this._plain);
    this._outline = strToBoolean(this._outline);
  }
  ngAfterViewInit() {
    const el = this.el.nativeElement;
    if (this._isSwitch) {
      this.renderer.addClass(el, `p-switch`);
    }
    if (this._bigger) {
      this.renderer.addClass(el, `p-bigger`);
    }
    if (this._enableFocus) {
      this.renderer.addClass(el, `p-has-focus`);
    }
    if (this._plain) {
      this.renderer.addClass(el, `p-plain`);
    }
    if (this._stroke) {
      this.renderer.addClass(el, `p-${this._stroke}`);
    }
    if (this._shape) {
      this.renderer.addClass(el, `p-${this._shape}`);
    }
    if (this._animation) {
      this.renderer.addClass(el, `p-${this._animation}`);
    }
    if (this._iconDir) {
      this.renderer.addClass(el, `p-icon`);
    }
    if (this._svgDir) {
      this.renderer.addClass(el, `p-svg`);
    }
    if (this._imgDir) {
      this.renderer.addClass(el, `p-image`);
    }
    if (this._hoverDir || this._hoverWillChangeDir) {
      this.renderer.addClass(el, `p-has-hover`);
    }
    if (this._indeterminateDir || this._indeterminateWillChangeDir) {
      this.renderer.addClass(el, `p-has-indeterminate`);
    }
    if (this._isToggle) {
      this.renderer.addClass(el, `p-toggle`);
    }
    if (!this._isSwitch && !this._iconDir && !this._svgDir && !this._imgDir) {
      this.renderer.addClass(el, `p-default`);
    }
    // STATE ELEM
    if (this._color && !this._isToggle) {
      this.renderer.addClass(this._stateElem.nativeElement, `p-${this._color}${this._outline ? '-o' : ''}`);
    }
  }
  // ---------- PRIVATE METHODS ---------
  _onClick(event, checked, value) {
    if (!this.stateless) {
      return;
    }
    event.stopPropagation();
    event.preventDefault();
    this.change.emit({
      value,
      checked,
      event
    });
  }
  _onChange(event, checked, value) {
    console.log('CHANGE', checked);
    // Stop propagation on the change event. Otherwise, the change event, from the input element, will bubble up
    event.stopPropagation();
    this.checked = checked;
    this.change.emit({
      value,
      checked,
      event
    });
  }
  // ---------- PUBLIC METHODS ---------
  setIndeterminate(value = true) {
    this._inputElem.nativeElement.indeterminate = value;
  }
  forceCheck(check) {
    this.checked = check;
    this.cd.markForCheck();
  }
  static {
    this.ɵfac = function NgxPrettyCheckboxComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NgxPrettyCheckboxComponent)(i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵinjectAttribute('stroke'), i0.ɵɵinjectAttribute('shape'), i0.ɵɵinjectAttribute('animation'), i0.ɵɵinjectAttribute('isSwitch'), i0.ɵɵinjectAttribute('bigger'), i0.ɵɵinjectAttribute('enableFocus'), i0.ɵɵinjectAttribute('plain'), i0.ɵɵinjectAttribute('outline'), i0.ɵɵinjectAttribute('color'));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: NgxPrettyCheckboxComponent,
      selectors: [["ngx-pretty-checkbox", 3, "will-change", ""], ["p-checkbox", 3, "will-change", ""]],
      contentQueries: function NgxPrettyCheckboxComponent_ContentQueries(rf, ctx, dirIndex) {
        if (rf & 1) {
          i0.ɵɵcontentQuery(dirIndex, NgxPrettyIconDirective, 5);
          i0.ɵɵcontentQuery(dirIndex, NgxPrettySvgDirective, 5);
          i0.ɵɵcontentQuery(dirIndex, NgxPrettyImageDirective, 5);
          i0.ɵɵcontentQuery(dirIndex, NgxPrettyHoverComponent, 5);
          i0.ɵɵcontentQuery(dirIndex, NgxPrettyHoverWillChangeComponent, 5);
          i0.ɵɵcontentQuery(dirIndex, NgxPrettyIndeterminateComponent, 5);
          i0.ɵɵcontentQuery(dirIndex, NgxPrettyIndeterminateWillChangeComponent, 5);
          i0.ɵɵcontentQuery(dirIndex, NgxPrettyToggleComponent, 4);
          i0.ɵɵcontentQuery(dirIndex, NgxPrettyToggleWillChangeComponent, 4);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx._iconDir = _t.first);
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx._svgDir = _t.first);
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx._imgDir = _t.first);
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx._hoverDir = _t.first);
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx._hoverWillChangeDir = _t.first);
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx._indeterminateDir = _t.first);
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx._indeterminateWillChangeDir = _t.first);
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx._toggleComps = _t);
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx._toggleWillChangeComps = _t);
        }
      },
      viewQuery: function NgxPrettyCheckboxComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(_c3, 7);
          i0.ɵɵviewQuery(_c4, 5);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx._inputElem = _t.first);
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx._stateElem = _t.first);
        }
      },
      hostAttrs: [1, "pretty"],
      hostVars: 2,
      hostBindings: function NgxPrettyCheckboxComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassProp("p-locked", ctx.lock);
        }
      },
      inputs: {
        checked: "checked",
        disabled: "disabled",
        value: "value",
        lock: "lock",
        stateless: "stateless"
      },
      outputs: {
        change: "change"
      },
      exportAs: ["ngxPrettyCheckbox"],
      ngContentSelectors: _c6,
      decls: 4,
      vars: 4,
      consts: [["inputElem", ""], ["stateElem", ""], ["type", "checkbox", 3, "click", "change", "value", "checked", "disabled"], ["class", "state", 4, "ngIf"], [1, "state"]],
      template: function NgxPrettyCheckboxComponent_Template(rf, ctx) {
        if (rf & 1) {
          const _r1 = i0.ɵɵgetCurrentView();
          i0.ɵɵprojectionDef(_c5);
          i0.ɵɵelementStart(0, "input", 2, 0);
          i0.ɵɵlistener("click", function NgxPrettyCheckboxComponent_Template_input_click_0_listener($event) {
            i0.ɵɵrestoreView(_r1);
            const inputElem_r2 = i0.ɵɵreference(1);
            return i0.ɵɵresetView(ctx._onClick($event, inputElem_r2.checked, inputElem_r2.value));
          })("change", function NgxPrettyCheckboxComponent_Template_input_change_0_listener($event) {
            i0.ɵɵrestoreView(_r1);
            const inputElem_r2 = i0.ɵɵreference(1);
            return i0.ɵɵresetView(ctx._onChange($event, inputElem_r2.checked, inputElem_r2.value));
          });
          i0.ɵɵelementEnd();
          i0.ɵɵtemplate(2, NgxPrettyCheckboxComponent_div_2_Template, 5, 0, "div", 3);
          i0.ɵɵprojection(3);
        }
        if (rf & 2) {
          i0.ɵɵproperty("value", ctx.value)("checked", ctx.checked)("disabled", ctx.disabled);
          i0.ɵɵadvance(2);
          i0.ɵɵproperty("ngIf", !ctx._isToggle);
        }
      },
      dependencies: [CommonModule, i1.NgIf],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxPrettyCheckboxComponent, [{
    type: Component,
    args: [{
      selector: 'ngx-pretty-checkbox:not([will-change]), p-checkbox:not([will-change])',
      standalone: true,
      imports: [CommonModule],
      encapsulation: ViewEncapsulation.None,
      changeDetection: ChangeDetectionStrategy.OnPush,
      host: {
        'class': 'pretty',
        '[class.p-locked]': 'lock'
      },
      template: `
    <input
        #inputElem
        type="checkbox"
        [value]="value"
        [checked]="checked"
        [disabled]="disabled"
        (click)="_onClick($event, inputElem.checked, inputElem.value)"
        (change)="_onChange($event, inputElem.checked, inputElem.value)"/>

    <div #stateElem *ngIf="!_isToggle" class="state">
      <ng-content select="[pIcon], [p-icon], [pSvg], [p-svg], [pImage], [p-image]"></ng-content>
      <label>
        <ng-content></ng-content>
      </label>
    </div>

    <ng-content
        select="ngx-p-indeterminate, p-indeterminate, ngx-p-hover, p-hover, ngx-p-toggle, p-toggle"></ng-content>
  `,
      exportAs: 'ngxPrettyCheckbox'
    }]
  }], () => [{
    type: i0.Renderer2
  }, {
    type: i0.ElementRef
  }, {
    type: i0.ChangeDetectorRef
  }, {
    type: PrettyCheckboxStroke,
    decorators: [{
      type: Attribute,
      args: ['stroke']
    }]
  }, {
    type: PrettyCheckboxShape,
    decorators: [{
      type: Attribute,
      args: ['shape']
    }]
  }, {
    type: PrettyCheckboxAnimation,
    decorators: [{
      type: Attribute,
      args: ['animation']
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Attribute,
      args: ['isSwitch']
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Attribute,
      args: ['bigger']
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Attribute,
      args: ['enableFocus']
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Attribute,
      args: ['plain']
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Attribute,
      args: ['outline']
    }]
  }, {
    type: PrettyCheckboxColor,
    decorators: [{
      type: Attribute,
      args: ['color']
    }]
  }], {
    checked: [{
      type: Input
    }],
    disabled: [{
      type: Input
    }],
    value: [{
      type: Input
    }],
    lock: [{
      type: Input
    }],
    stateless: [{
      type: Input
    }],
    _inputElem: [{
      type: ViewChild,
      args: ['inputElem', {
        static: true
      }]
    }],
    _stateElem: [{
      type: ViewChild,
      args: ['stateElem', {
        static: false
      }]
    }],
    change: [{
      type: Output
    }],
    _iconDir: [{
      type: ContentChild,
      args: [NgxPrettyIconDirective, {
        static: false
      }]
    }],
    _svgDir: [{
      type: ContentChild,
      args: [NgxPrettySvgDirective, {
        static: false
      }]
    }],
    _imgDir: [{
      type: ContentChild,
      args: [NgxPrettyImageDirective, {
        static: false
      }]
    }],
    _hoverDir: [{
      type: ContentChild,
      args: [NgxPrettyHoverComponent, {
        static: false
      }]
    }],
    _hoverWillChangeDir: [{
      type: ContentChild,
      args: [NgxPrettyHoverWillChangeComponent, {
        static: false
      }]
    }],
    _indeterminateDir: [{
      type: ContentChild,
      args: [NgxPrettyIndeterminateComponent, {
        static: false
      }]
    }],
    _indeterminateWillChangeDir: [{
      type: ContentChild,
      args: [NgxPrettyIndeterminateWillChangeComponent, {
        static: false
      }]
    }],
    _toggleComps: [{
      type: ContentChildren,
      args: [NgxPrettyToggleComponent]
    }],
    _toggleWillChangeComps: [{
      type: ContentChildren,
      args: [NgxPrettyToggleWillChangeComponent]
    }]
  });
})();
class NgxPrettyCheckboxWillChangeComponent {
  constructor(cd) {
    this.cd = cd;
    this.change = new EventEmitter();
    this.outline = false; // COLOR . Can be used as Solid ( p-primary ) or Outline ( p-primary-o ).
    this.checked = false;
    this.disabled = false;
    this.stateless = false;
    // ----- INPUTS AND BINDING ------
    this.isSwitch = false;
    this.lock = false;
    this.bigger = false;
    this.enableFocus = false;
    this.plain = false; // To remove the border ( when checkbox is checked )
  }
  // ---------- STROKE ----------
  get _isFill() {
    return this.stroke === PrettyCheckboxStroke.Fill;
  }
  get _isSlim() {
    return this.stroke === PrettyCheckboxStroke.Slim;
  }
  get _isThick() {
    return this.stroke === PrettyCheckboxStroke.Thick;
  }
  // ---------- SHAPE ----------
  get _isCurve() {
    return this.shape === PrettyCheckboxShape.Curve;
  }
  get _isRound() {
    return this.shape === PrettyCheckboxShape.Round;
  }
  // ---------- ANIMATION ----------
  get _isSmooth() {
    return this.animation === PrettyCheckboxAnimation.Smooth;
  }
  get _isJelly() {
    return this.animation === PrettyCheckboxAnimation.Jelly;
  }
  get _isTada() {
    return this.animation === PrettyCheckboxAnimation.Tada;
  }
  get _isRotate() {
    return this.animation === PrettyCheckboxAnimation.Rotate;
  }
  get _isPulse() {
    return this.animation === PrettyCheckboxAnimation.Pulse;
  }
  get _isToggle() {
    return this._toggleComp.length === 2 || this._toggleWillChangeComp.length === 2;
  }
  // DEFAULT
  get _isDefault() {
    return !this.isSwitch && !this._iconElem && !this._svgElem && !this._imageElem;
  }
  get _isHover() {
    return this._hoverComp || this._hoverWillChangeDir;
  }
  get _isIndeterminate() {
    return this._indeterminateComp || this._indeterminateWillChangeComp;
  }
  // ---------- PRIVATE METHODS ---------
  _onClick(event, checked, value) {
    if (!this.stateless) {
      return;
    }
    event.stopPropagation();
    event.preventDefault();
    this.change.emit({
      value,
      checked,
      event
    });
  }
  _onChange(event, checked, value) {
    // Stop propagation on the change event. Otherwise, the change event, from the input element, will bubble up
    event.stopPropagation();
    this.checked = checked;
    this.change.emit({
      value,
      checked,
      event
    });
  }
  // ---------- PUBLIC METHODS ---------
  setIndeterminate(value = true) {
    this._inputElem.nativeElement.indeterminate = value;
  }
  forceCheck(check) {
    this.checked = check;
    this.cd.markForCheck();
  }
  static {
    this.ɵfac = function NgxPrettyCheckboxWillChangeComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NgxPrettyCheckboxWillChangeComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: NgxPrettyCheckboxWillChangeComponent,
      selectors: [["ngx-pretty-checkbox", "will-change", ""], ["p-checkbox", "will-change", ""]],
      contentQueries: function NgxPrettyCheckboxWillChangeComponent_ContentQueries(rf, ctx, dirIndex) {
        if (rf & 1) {
          i0.ɵɵcontentQuery(dirIndex, NgxPrettyIconDirective, 5);
          i0.ɵɵcontentQuery(dirIndex, NgxPrettySvgDirective, 5);
          i0.ɵɵcontentQuery(dirIndex, NgxPrettyImageDirective, 5);
          i0.ɵɵcontentQuery(dirIndex, NgxPrettyHoverWillChangeComponent, 5);
          i0.ɵɵcontentQuery(dirIndex, NgxPrettyHoverComponent, 5);
          i0.ɵɵcontentQuery(dirIndex, NgxPrettyIndeterminateComponent, 5);
          i0.ɵɵcontentQuery(dirIndex, NgxPrettyIndeterminateWillChangeComponent, 5);
          i0.ɵɵcontentQuery(dirIndex, NgxPrettyToggleComponent, 4);
          i0.ɵɵcontentQuery(dirIndex, NgxPrettyToggleWillChangeComponent, 4);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx._iconElem = _t.first);
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx._svgElem = _t.first);
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx._imageElem = _t.first);
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx._hoverWillChangeDir = _t.first);
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx._hoverComp = _t.first);
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx._indeterminateComp = _t.first);
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx._indeterminateWillChangeComp = _t.first);
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx._toggleComp = _t);
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx._toggleWillChangeComp = _t);
        }
      },
      viewQuery: function NgxPrettyCheckboxWillChangeComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(_c3, 7);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx._inputElem = _t.first);
        }
      },
      hostAttrs: [1, "pretty"],
      hostVars: 44,
      hostBindings: function NgxPrettyCheckboxWillChangeComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassProp("p-switch", ctx.isSwitch)("p-locked", ctx.lock)("p-bigger", ctx.bigger)("p-has-focus", ctx.enableFocus)("p-plain", ctx.plain)("p-fill", ctx._isFill)("p-slim", ctx._isSlim)("p-thick", ctx._isThick)("p-curve", ctx._isCurve)("p-round", ctx._isRound)("p-smooth", ctx._isSmooth)("p-jelly", ctx._isJelly)("p-tada", ctx._isTada)("p-rotate", ctx._isRotate)("p-pulse", ctx._isPulse)("p-toggle", ctx._isToggle)("p-default", ctx._isDefault)("p-icon", ctx._iconElem)("p-svg", ctx._svgElem)("p-image", ctx._imageElem)("p-has-hover", ctx._isHover)("p-has-indeterminate", ctx._isIndeterminate);
        }
      },
      inputs: {
        stroke: "stroke",
        shape: "shape",
        animation: "animation",
        color: "color",
        outline: "outline",
        checked: "checked",
        disabled: "disabled",
        value: "value",
        stateless: "stateless",
        isSwitch: "isSwitch",
        lock: "lock",
        bigger: "bigger",
        enableFocus: "enableFocus",
        plain: "plain"
      },
      outputs: {
        change: "change"
      },
      exportAs: ["ngxPrettyCheckboxWillChange"],
      attrs: _c2,
      ngContentSelectors: _c6,
      decls: 4,
      vars: 4,
      consts: [["inputElem", ""], ["type", "checkbox", 3, "click", "change", "value", "checked", "disabled"], ["class", "state", 3, "ngClass", 4, "ngIf"], [1, "state", 3, "ngClass"]],
      template: function NgxPrettyCheckboxWillChangeComponent_Template(rf, ctx) {
        if (rf & 1) {
          const _r1 = i0.ɵɵgetCurrentView();
          i0.ɵɵprojectionDef(_c5);
          i0.ɵɵelementStart(0, "input", 1, 0);
          i0.ɵɵlistener("click", function NgxPrettyCheckboxWillChangeComponent_Template_input_click_0_listener($event) {
            i0.ɵɵrestoreView(_r1);
            const inputElem_r2 = i0.ɵɵreference(1);
            return i0.ɵɵresetView(ctx._onClick($event, inputElem_r2.checked, inputElem_r2.value));
          })("change", function NgxPrettyCheckboxWillChangeComponent_Template_input_change_0_listener($event) {
            i0.ɵɵrestoreView(_r1);
            const inputElem_r2 = i0.ɵɵreference(1);
            return i0.ɵɵresetView(ctx._onChange($event, inputElem_r2.checked, inputElem_r2.value));
          });
          i0.ɵɵelementEnd();
          i0.ɵɵtemplate(2, NgxPrettyCheckboxWillChangeComponent_div_2_Template, 4, 3, "div", 2);
          i0.ɵɵprojection(3);
        }
        if (rf & 2) {
          i0.ɵɵproperty("value", ctx.value)("checked", ctx.checked)("disabled", ctx.disabled);
          i0.ɵɵadvance(2);
          i0.ɵɵproperty("ngIf", !ctx._isToggle);
        }
      },
      dependencies: [CommonModule, i1.NgClass, i1.NgIf],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxPrettyCheckboxWillChangeComponent, [{
    type: Component,
    args: [{
      selector: 'ngx-pretty-checkbox[will-change], p-checkbox[will-change]',
      standalone: true,
      imports: [CommonModule],
      encapsulation: ViewEncapsulation.None,
      changeDetection: ChangeDetectionStrategy.OnPush,
      host: {
        'class': 'pretty',
        '[class.p-switch]': 'isSwitch',
        '[class.p-locked]': 'lock',
        '[class.p-bigger]': 'bigger',
        '[class.p-has-focus]': 'enableFocus',
        '[class.p-plain]': 'plain',
        '[class.p-fill]': '_isFill',
        '[class.p-slim]': '_isSlim',
        '[class.p-thick]': '_isThick',
        '[class.p-curve]': '_isCurve',
        '[class.p-round]': '_isRound',
        '[class.p-smooth]': '_isSmooth',
        '[class.p-jelly]': '_isJelly',
        '[class.p-tada]': '_isTada',
        '[class.p-rotate]': '_isRotate',
        '[class.p-pulse]': '_isPulse',
        '[class.p-toggle]': '_isToggle',
        '[class.p-default]': '_isDefault',
        '[class.p-icon]': '_iconElem',
        '[class.p-svg]': '_svgElem',
        '[class.p-image]': '_imageElem',
        '[class.p-has-hover]': '_isHover',
        '[class.p-has-indeterminate]': '_isIndeterminate'
      },
      template: `
    <input
        #inputElem
        type="checkbox"
        [value]="value"
        [checked]="checked"
        [disabled]="disabled"
        (click)="_onClick($event, inputElem.checked, inputElem.value)"
        (change)="_onChange($event, inputElem.checked, inputElem.value)"/>

    <div
        *ngIf="!_isToggle"
        class="state"
        [ngClass]="[ color ? 'p-' + color + (outline ? '-o' : '') : '' ]">
      <ng-content select="[pIcon], [p-icon], [pSvg], [p-svg], [pImage], [p-image]"></ng-content>
      <label><ng-content></ng-content></label>
    </div>

    <ng-content select="ngx-p-indeterminate, p-indeterminate, ngx-p-hover, p-hover, ngx-p-toggle, p-toggle">
    </ng-content>`,
      exportAs: 'ngxPrettyCheckboxWillChange'
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }], {
    _inputElem: [{
      type: ViewChild,
      args: ['inputElem', {
        static: true
      }]
    }],
    change: [{
      type: Output
    }],
    stroke: [{
      type: Input
    }],
    shape: [{
      type: Input
    }],
    animation: [{
      type: Input
    }],
    color: [{
      type: Input
    }],
    outline: [{
      type: Input
    }],
    checked: [{
      type: Input
    }],
    disabled: [{
      type: Input
    }],
    value: [{
      type: Input
    }],
    stateless: [{
      type: Input
    }],
    isSwitch: [{
      type: Input
    }],
    lock: [{
      type: Input
    }],
    bigger: [{
      type: Input
    }],
    enableFocus: [{
      type: Input
    }],
    plain: [{
      type: Input
    }],
    _toggleComp: [{
      type: ContentChildren,
      args: [NgxPrettyToggleComponent]
    }],
    _toggleWillChangeComp: [{
      type: ContentChildren,
      args: [NgxPrettyToggleWillChangeComponent]
    }],
    _iconElem: [{
      type: ContentChild,
      args: [NgxPrettyIconDirective, {
        static: false
      }]
    }],
    _svgElem: [{
      type: ContentChild,
      args: [NgxPrettySvgDirective, {
        static: false
      }]
    }],
    _imageElem: [{
      type: ContentChild,
      args: [NgxPrettyImageDirective, {
        static: false
      }]
    }],
    _hoverWillChangeDir: [{
      type: ContentChild,
      args: [NgxPrettyHoverWillChangeComponent, {
        static: false
      }]
    }],
    _hoverComp: [{
      type: ContentChild,
      args: [NgxPrettyHoverComponent, {
        static: false
      }]
    }],
    _indeterminateComp: [{
      type: ContentChild,
      args: [NgxPrettyIndeterminateComponent, {
        static: false
      }]
    }],
    _indeterminateWillChangeComp: [{
      type: ContentChild,
      args: [NgxPrettyIndeterminateWillChangeComponent, {
        static: false
      }]
    }]
  });
})();
let nextId = 1;
class NgxPrettyRadioGroupDirective {
  constructor() {
    this.name = 'p-radio' + nextId++;
    this.change = new EventEmitter();
  }
  _emitChange(event) {
    this.change.emit(event);
  }
  static {
    this.ɵfac = function NgxPrettyRadioGroupDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NgxPrettyRadioGroupDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: NgxPrettyRadioGroupDirective,
      selectors: [["ngx-pretty-radio-group"], ["p-radio-group"]],
      outputs: {
        change: "change"
      },
      exportAs: ["ngxRadioGroup"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxPrettyRadioGroupDirective, [{
    type: Directive,
    args: [{
      selector: 'ngx-pretty-radio-group, p-radio-group',
      standalone: true,
      exportAs: 'ngxRadioGroup'
    }]
  }], () => [], {
    change: [{
      type: Output
    }]
  });
})();
class NgxPrettyRadioComponent {
  constructor(renderer, el, radioGroup, _stroke, _shape, _animation, _isSwitch = false, _bigger = false, _enableFocus = false, _plain = false, _outline = false, _color, _name) {
    this.renderer = renderer;
    this.el = el;
    this.radioGroup = radioGroup;
    this._stroke = _stroke;
    this._shape = _shape;
    this._animation = _animation;
    this._isSwitch = _isSwitch;
    this._bigger = _bigger;
    this._enableFocus = _enableFocus;
    this._plain = _plain;
    this._outline = _outline;
    this._color = _color;
    this._name = _name;
    this.checked = false;
    this.disabled = false;
    this.lock = false;
    this.change = new EventEmitter();
    if (!this._name && this.radioGroup) {
      this._name = this.radioGroup.name;
    }
    this._isSwitch = strToBoolean(this._isSwitch);
    this._bigger = strToBoolean(this._bigger);
    this._enableFocus = strToBoolean(this._enableFocus);
    this._plain = strToBoolean(this._plain);
    this._outline = strToBoolean(this._outline);
  }
  ngAfterViewInit() {
    const el = this.el.nativeElement;
    if (this._isSwitch) {
      this.renderer.addClass(el, `p-switch`);
    }
    if (this._bigger) {
      this.renderer.addClass(el, `p-bigger`);
    }
    if (this._enableFocus) {
      this.renderer.addClass(el, `p-has-focus`);
    }
    if (this._plain) {
      this.renderer.addClass(el, `p-plain`);
    }
    if (this._stroke) {
      this.renderer.addClass(el, `p-${this._stroke}`);
    }
    if (this._shape) {
      this.renderer.addClass(el, `p-${this._shape}`);
    }
    if (this._animation) {
      this.renderer.addClass(el, `p-${this._animation}`);
    }
    if (this._iconDir) {
      this.renderer.addClass(el, `p-icon`);
    }
    if (this._svgDir) {
      this.renderer.addClass(el, `p-svg`);
    }
    if (this._imgDir) {
      this.renderer.addClass(el, `p-image`);
    }
    if (this._hoverDir || this._hoverWillChangeDir) {
      this.renderer.addClass(el, `p-has-hover`);
    }
    if (this._isToggle) {
      this.renderer.addClass(el, `p-toggle`);
    }
    if (!this._isSwitch && !this._iconDir && !this._svgDir && !this._imgDir) {
      this.renderer.addClass(el, `p-default`);
    }
    // STATE ELEM
    if (this._color && !this._isToggle) {
      this.renderer.addClass(this._stateElem.nativeElement, `p-${this._color}${this._outline ? '-o' : ''}`);
    }
  }
  // ---------- METHODS ---------
  _onChange(event, checked, value) {
    // I stop propagation on the change event.
    // Otherwise the change event, from the input element, will bubble up and
    event.stopPropagation();
    this.change.emit({
      value,
      checked,
      event
    });
    if (this.radioGroup) {
      this.radioGroup._emitChange({
        value,
        checked,
        event
      });
    }
  }
  get _isToggle() {
    return this._toggleComps.length === 2 || this._toggleWillChangeComps.length === 2;
  }
  static {
    this.ɵfac = function NgxPrettyRadioComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NgxPrettyRadioComponent)(i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(NgxPrettyRadioGroupDirective, 8), i0.ɵɵinjectAttribute('stroke'), i0.ɵɵinjectAttribute('shape'), i0.ɵɵinjectAttribute('animation'), i0.ɵɵinjectAttribute('isSwitch'), i0.ɵɵinjectAttribute('bigger'), i0.ɵɵinjectAttribute('enableFocus'), i0.ɵɵinjectAttribute('plain'), i0.ɵɵinjectAttribute('outline'), i0.ɵɵinjectAttribute('color'), i0.ɵɵinjectAttribute('name'));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: NgxPrettyRadioComponent,
      selectors: [["ngx-pretty-radio", 3, "will-change", ""], ["p-radio", 3, "will-change", ""]],
      contentQueries: function NgxPrettyRadioComponent_ContentQueries(rf, ctx, dirIndex) {
        if (rf & 1) {
          i0.ɵɵcontentQuery(dirIndex, NgxPrettyIconDirective, 5);
          i0.ɵɵcontentQuery(dirIndex, NgxPrettySvgDirective, 5);
          i0.ɵɵcontentQuery(dirIndex, NgxPrettyImageDirective, 5);
          i0.ɵɵcontentQuery(dirIndex, NgxPrettyHoverComponent, 5);
          i0.ɵɵcontentQuery(dirIndex, NgxPrettyHoverWillChangeComponent, 5);
          i0.ɵɵcontentQuery(dirIndex, NgxPrettyToggleComponent, 4);
          i0.ɵɵcontentQuery(dirIndex, NgxPrettyToggleWillChangeComponent, 4);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx._iconDir = _t.first);
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx._svgDir = _t.first);
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx._imgDir = _t.first);
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx._hoverDir = _t.first);
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx._hoverWillChangeDir = _t.first);
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx._toggleComps = _t);
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx._toggleWillChangeComps = _t);
        }
      },
      viewQuery: function NgxPrettyRadioComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(_c3, 7);
          i0.ɵɵviewQuery(_c4, 5);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx._inputElem = _t.first);
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx._stateElem = _t.first);
        }
      },
      hostAttrs: [1, "pretty"],
      hostVars: 2,
      hostBindings: function NgxPrettyRadioComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassProp("p-locked", ctx.lock);
        }
      },
      inputs: {
        checked: "checked",
        disabled: "disabled",
        value: "value",
        lock: "lock"
      },
      outputs: {
        change: "change"
      },
      exportAs: ["ngxPrettyRadio"],
      ngContentSelectors: _c9,
      decls: 4,
      vars: 5,
      consts: [["inputElem", ""], ["stateElem", ""], ["type", "radio", 3, "change", "name", "value", "checked", "disabled"], ["class", "state", 4, "ngIf"], [1, "state"]],
      template: function NgxPrettyRadioComponent_Template(rf, ctx) {
        if (rf & 1) {
          const _r1 = i0.ɵɵgetCurrentView();
          i0.ɵɵprojectionDef(_c8);
          i0.ɵɵelementStart(0, "input", 2, 0);
          i0.ɵɵlistener("change", function NgxPrettyRadioComponent_Template_input_change_0_listener($event) {
            i0.ɵɵrestoreView(_r1);
            const inputElem_r2 = i0.ɵɵreference(1);
            return i0.ɵɵresetView(ctx._onChange($event, inputElem_r2.checked, inputElem_r2.value));
          });
          i0.ɵɵelementEnd();
          i0.ɵɵtemplate(2, NgxPrettyRadioComponent_div_2_Template, 5, 0, "div", 3);
          i0.ɵɵprojection(3);
        }
        if (rf & 2) {
          i0.ɵɵproperty("name", ctx._name)("value", ctx.value)("checked", ctx.checked)("disabled", ctx.disabled);
          i0.ɵɵadvance(2);
          i0.ɵɵproperty("ngIf", !ctx._isToggle);
        }
      },
      dependencies: [CommonModule, i1.NgIf],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxPrettyRadioComponent, [{
    type: Component,
    args: [{
      selector: 'ngx-pretty-radio:not([will-change]), p-radio:not([will-change])',
      standalone: true,
      imports: [CommonModule],
      encapsulation: ViewEncapsulation.None,
      changeDetection: ChangeDetectionStrategy.OnPush,
      host: {
        'class': 'pretty',
        '[class.p-locked]': 'lock'
      },
      template: `
    <input
        #inputElem
        type="radio"
        [name]="_name"
        [value]="value"
        [checked]="checked"
        [disabled]="disabled"
        (change)="_onChange($event, inputElem.checked, inputElem.value)"/>

    <div #stateElem *ngIf="!_isToggle" class="state">

      <ng-content select="[pIcon], [p-icon], [pSvg], [p-svg], [pImage], [p-image]"></ng-content>
      <label><ng-content></ng-content></label>

    </div>

    <ng-content select="ngx-p-hover, p-hover, ngx-p-toggle, p-toggle"></ng-content>
  `,
      exportAs: 'ngxPrettyRadio'
    }]
  }], () => [{
    type: i0.Renderer2
  }, {
    type: i0.ElementRef
  }, {
    type: NgxPrettyRadioGroupDirective,
    decorators: [{
      type: Optional
    }]
  }, {
    type: PrettyCheckboxStroke,
    decorators: [{
      type: Attribute,
      args: ['stroke']
    }]
  }, {
    type: PrettyCheckboxShape,
    decorators: [{
      type: Attribute,
      args: ['shape']
    }]
  }, {
    type: PrettyCheckboxAnimation,
    decorators: [{
      type: Attribute,
      args: ['animation']
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Attribute,
      args: ['isSwitch']
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Attribute,
      args: ['bigger']
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Attribute,
      args: ['enableFocus']
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Attribute,
      args: ['plain']
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Attribute,
      args: ['outline']
    }]
  }, {
    type: PrettyCheckboxColor,
    decorators: [{
      type: Attribute,
      args: ['color']
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Attribute,
      args: ['name']
    }]
  }], {
    checked: [{
      type: Input
    }],
    disabled: [{
      type: Input
    }],
    value: [{
      type: Input
    }],
    lock: [{
      type: Input
    }],
    _inputElem: [{
      type: ViewChild,
      args: ['inputElem', {
        static: true
      }]
    }],
    _stateElem: [{
      type: ViewChild,
      args: ['stateElem', {
        static: false
      }]
    }],
    change: [{
      type: Output
    }],
    _iconDir: [{
      type: ContentChild,
      args: [NgxPrettyIconDirective, {
        static: false
      }]
    }],
    _svgDir: [{
      type: ContentChild,
      args: [NgxPrettySvgDirective, {
        static: false
      }]
    }],
    _imgDir: [{
      type: ContentChild,
      args: [NgxPrettyImageDirective, {
        static: false
      }]
    }],
    _hoverDir: [{
      type: ContentChild,
      args: [NgxPrettyHoverComponent, {
        static: false
      }]
    }],
    _hoverWillChangeDir: [{
      type: ContentChild,
      args: [NgxPrettyHoverWillChangeComponent, {
        static: false
      }]
    }],
    _toggleComps: [{
      type: ContentChildren,
      args: [NgxPrettyToggleComponent]
    }],
    _toggleWillChangeComps: [{
      type: ContentChildren,
      args: [NgxPrettyToggleWillChangeComponent]
    }]
  });
})();
class NgxPrettyRadioWillChangeComponent {
  constructor(radioGroup) {
    this.radioGroup = radioGroup;
    this.change = new EventEmitter();
    this.outline = false; // COLOR . Can be used as Solid ( p-primary ) or Outline ( p-primary-o ).
    this.checked = false;
    this.disabled = false;
    // ----- INPUTS AND BINDING ------
    this.isSwitch = false;
    this.lock = false;
    this.bigger = false;
    this.enableFocus = false;
    this.plain = false; // To remove the border ( when checkbox is checked )
    if (radioGroup) {
      this.radioGroupName = radioGroup.name;
    }
  }
  // ---------- STROKE ----------
  get _isFill() {
    return this.stroke === PrettyCheckboxStroke.Fill;
  }
  get _isSlim() {
    return this.stroke === PrettyCheckboxStroke.Slim;
  }
  get _isThick() {
    return this.stroke === PrettyCheckboxStroke.Thick;
  }
  // ---------- SHAPE ----------
  get _isCurve() {
    return this.shape === PrettyCheckboxShape.Curve;
  }
  get _isRound() {
    return this.shape === PrettyCheckboxShape.Round;
  }
  // ---------- ANIMATION ----------
  get _isSmooth() {
    return this.animation === PrettyCheckboxAnimation.Smooth;
  }
  get _isJelly() {
    return this.animation === PrettyCheckboxAnimation.Jelly;
  }
  get _isTada() {
    return this.animation === PrettyCheckboxAnimation.Tada;
  }
  get _isRotate() {
    return this.animation === PrettyCheckboxAnimation.Rotate;
  }
  get _isPulse() {
    return this.animation === PrettyCheckboxAnimation.Pulse;
  }
  get _isToggle() {
    return this._toggleComp.length === 2 || this._toggleWillChangeComp.length === 2;
  }
  // DEFAULT
  get _isDefault() {
    return !this.isSwitch && !this._iconElem && !this._svgElem && !this._imageElem;
  }
  get _isHover() {
    return this._hoverComp || this._hoverWillChangeDir;
  }
  // ---------- METHODS ---------
  _onChange(event, checked, value) {
    // I stop propagation on the change event.
    // Otherwise the change event, from the input element, will bubble up and
    event.stopPropagation();
    this.change.emit({
      value,
      checked,
      event
    });
    if (this.radioGroup) {
      this.radioGroup._emitChange({
        value,
        checked,
        event
      });
    }
  }
  static {
    this.ɵfac = function NgxPrettyRadioWillChangeComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NgxPrettyRadioWillChangeComponent)(i0.ɵɵdirectiveInject(NgxPrettyRadioGroupDirective, 8));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: NgxPrettyRadioWillChangeComponent,
      selectors: [["ngx-pretty-radio", "will-change", ""], ["p-radio", "will-change", ""]],
      contentQueries: function NgxPrettyRadioWillChangeComponent_ContentQueries(rf, ctx, dirIndex) {
        if (rf & 1) {
          i0.ɵɵcontentQuery(dirIndex, NgxPrettyIconDirective, 5);
          i0.ɵɵcontentQuery(dirIndex, NgxPrettySvgDirective, 5);
          i0.ɵɵcontentQuery(dirIndex, NgxPrettyImageDirective, 5);
          i0.ɵɵcontentQuery(dirIndex, NgxPrettyHoverWillChangeComponent, 5);
          i0.ɵɵcontentQuery(dirIndex, NgxPrettyHoverComponent, 5);
          i0.ɵɵcontentQuery(dirIndex, NgxPrettyToggleComponent, 4);
          i0.ɵɵcontentQuery(dirIndex, NgxPrettyToggleWillChangeComponent, 4);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx._iconElem = _t.first);
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx._svgElem = _t.first);
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx._imageElem = _t.first);
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx._hoverWillChangeDir = _t.first);
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx._hoverComp = _t.first);
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx._toggleComp = _t);
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx._toggleWillChangeComp = _t);
        }
      },
      viewQuery: function NgxPrettyRadioWillChangeComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(_c3, 7);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx._inputElem = _t.first);
        }
      },
      hostAttrs: [1, "pretty"],
      hostVars: 42,
      hostBindings: function NgxPrettyRadioWillChangeComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassProp("p-switch", ctx.isSwitch)("p-locked", ctx.lock)("p-bigger", ctx.bigger)("p-has-focus", ctx.enableFocus)("p-plain", ctx.plain)("p-fill", ctx._isFill)("p-thick", ctx._isSlim)("p-slim", ctx._isThick)("p-curve", ctx._isCurve)("p-round", ctx._isRound)("p-smooth", ctx._isSmooth)("p-jelly", ctx._isJelly)("p-tada", ctx._isTada)("p-rotate", ctx._isRotate)("p-pulse", ctx._isPulse)("p-toggle", ctx._isToggle)("p-default", ctx._isDefault)("p-icon", ctx._iconElem)("p-svg", ctx._svgElem)("p-image", ctx._imageElem)("p-has-hover", ctx._isHover);
        }
      },
      inputs: {
        stroke: "stroke",
        shape: "shape",
        animation: "animation",
        color: "color",
        outline: "outline",
        checked: "checked",
        disabled: "disabled",
        value: "value",
        name: "name",
        isSwitch: "isSwitch",
        lock: "lock",
        bigger: "bigger",
        enableFocus: "enableFocus",
        plain: "plain"
      },
      outputs: {
        change: "change"
      },
      exportAs: ["ngxPrettyRadio"],
      attrs: _c2,
      ngContentSelectors: _c6,
      decls: 4,
      vars: 5,
      consts: [["inputElem", ""], ["type", "radio", 3, "change", "name", "value", "checked", "disabled"], ["class", "state", 3, "ngClass", 4, "ngIf"], [1, "state", 3, "ngClass"]],
      template: function NgxPrettyRadioWillChangeComponent_Template(rf, ctx) {
        if (rf & 1) {
          const _r1 = i0.ɵɵgetCurrentView();
          i0.ɵɵprojectionDef(_c5);
          i0.ɵɵelementStart(0, "input", 1, 0);
          i0.ɵɵlistener("change", function NgxPrettyRadioWillChangeComponent_Template_input_change_0_listener($event) {
            i0.ɵɵrestoreView(_r1);
            const inputElem_r2 = i0.ɵɵreference(1);
            return i0.ɵɵresetView(ctx._onChange($event, inputElem_r2.checked, inputElem_r2.value));
          });
          i0.ɵɵelementEnd();
          i0.ɵɵtemplate(2, NgxPrettyRadioWillChangeComponent_div_2_Template, 4, 3, "div", 2);
          i0.ɵɵprojection(3);
        }
        if (rf & 2) {
          i0.ɵɵproperty("name", ctx.name || ctx.radioGroupName)("value", ctx.value)("checked", ctx.checked)("disabled", ctx.disabled);
          i0.ɵɵadvance(2);
          i0.ɵɵproperty("ngIf", !ctx._isToggle);
        }
      },
      dependencies: [CommonModule, i1.NgClass, i1.NgIf],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxPrettyRadioWillChangeComponent, [{
    type: Component,
    args: [{
      selector: 'ngx-pretty-radio[will-change], p-radio[will-change]',
      standalone: true,
      imports: [CommonModule],
      encapsulation: ViewEncapsulation.None,
      changeDetection: ChangeDetectionStrategy.OnPush,
      host: {
        'class': 'pretty',
        '[class.p-switch]': 'isSwitch',
        '[class.p-locked]': 'lock',
        '[class.p-bigger]': 'bigger',
        '[class.p-has-focus]': 'enableFocus',
        '[class.p-plain]': 'plain',
        '[class.p-fill]': '_isFill',
        '[class.p-thick]': '_isSlim',
        '[class.p-slim]': '_isThick',
        '[class.p-curve]': '_isCurve',
        '[class.p-round]': '_isRound',
        '[class.p-smooth]': '_isSmooth',
        '[class.p-jelly]': '_isJelly',
        '[class.p-tada]': '_isTada',
        '[class.p-rotate]': '_isRotate',
        '[class.p-pulse]': '_isPulse',
        '[class.p-toggle]': '_isToggle',
        '[class.p-default]': '_isDefault',
        '[class.p-icon]': '_iconElem',
        '[class.p-svg]': '_svgElem',
        '[class.p-image]': '_imageElem',
        '[class.p-has-hover]': '_isHover'
      },
      template: `
    <input
        #inputElem
        type="radio"
        [name]="name || radioGroupName"
        [value]="value"
        [checked]="checked"
        [disabled]="disabled"
        (change)="_onChange($event, inputElem.checked, inputElem.value)"/>

    <div *ngIf="!_isToggle" class="state"
         [ngClass]="[ color ? 'p-' + color + (outline ? '-o' : '') : '' ]">

      <ng-content select="[pIcon], [p-icon], [pSvg], [p-svg], [pImage], [p-image]"></ng-content>
      <label><ng-content></ng-content></label>
    </div>

    <ng-content select="ngx-p-indeterminate, p-indeterminate, ngx-p-hover, p-hover, ngx-p-toggle, p-toggle"></ng-content>
  `,
      exportAs: 'ngxPrettyRadio'
    }]
  }], () => [{
    type: NgxPrettyRadioGroupDirective,
    decorators: [{
      type: Optional
    }]
  }], {
    _inputElem: [{
      type: ViewChild,
      args: ['inputElem', {
        static: true
      }]
    }],
    change: [{
      type: Output
    }],
    stroke: [{
      type: Input
    }],
    shape: [{
      type: Input
    }],
    animation: [{
      type: Input
    }],
    color: [{
      type: Input
    }],
    outline: [{
      type: Input
    }],
    checked: [{
      type: Input
    }],
    disabled: [{
      type: Input
    }],
    value: [{
      type: Input
    }],
    name: [{
      type: Input
    }],
    isSwitch: [{
      type: Input
    }],
    lock: [{
      type: Input
    }],
    bigger: [{
      type: Input
    }],
    enableFocus: [{
      type: Input
    }],
    plain: [{
      type: Input
    }],
    _toggleComp: [{
      type: ContentChildren,
      args: [NgxPrettyToggleComponent]
    }],
    _toggleWillChangeComp: [{
      type: ContentChildren,
      args: [NgxPrettyToggleWillChangeComponent]
    }],
    _iconElem: [{
      type: ContentChild,
      args: [NgxPrettyIconDirective, {
        static: false
      }]
    }],
    _svgElem: [{
      type: ContentChild,
      args: [NgxPrettySvgDirective, {
        static: false
      }]
    }],
    _imageElem: [{
      type: ContentChild,
      args: [NgxPrettyImageDirective, {
        static: false
      }]
    }],
    _hoverWillChangeDir: [{
      type: ContentChild,
      args: [NgxPrettyHoverWillChangeComponent, {
        static: false
      }]
    }],
    _hoverComp: [{
      type: ContentChild,
      args: [NgxPrettyHoverComponent, {
        static: false
      }]
    }]
  });
})();
const DECLARATIONS = [NgxPrettyCheckboxComponent, NgxPrettyCheckboxWillChangeComponent, NgxPrettyIconDirective, NgxPrettySvgDirective, NgxPrettyImageDirective, NgxPrettyToggleComponent, NgxPrettyToggleWillChangeComponent, NgxPrettyHoverComponent, NgxPrettyHoverWillChangeComponent, NgxPrettyIndeterminateComponent, NgxPrettyIndeterminateWillChangeComponent, NgxPrettyRadioGroupDirective, NgxPrettyRadioComponent, NgxPrettyRadioWillChangeComponent];
class NgxPrettyCheckboxModule {
  static {
    this.ɵfac = function NgxPrettyCheckboxModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NgxPrettyCheckboxModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: NgxPrettyCheckboxModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [NgxPrettyCheckboxComponent, NgxPrettyCheckboxWillChangeComponent, NgxPrettyToggleComponent, NgxPrettyToggleWillChangeComponent, NgxPrettyHoverComponent, NgxPrettyHoverWillChangeComponent, NgxPrettyIndeterminateComponent, NgxPrettyIndeterminateWillChangeComponent, NgxPrettyRadioComponent, NgxPrettyRadioWillChangeComponent]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxPrettyCheckboxModule, [{
    type: NgModule,
    args: [{
      imports: [DECLARATIONS],
      exports: [DECLARATIONS]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { NgxPrettyCheckboxComponent, NgxPrettyCheckboxModule, NgxPrettyCheckboxWillChangeComponent, NgxPrettyHoverComponent, NgxPrettyHoverWillChangeComponent, NgxPrettyIconDirective, NgxPrettyImageDirective, NgxPrettyIndeterminateComponent, NgxPrettyIndeterminateWillChangeComponent, NgxPrettyRadioComponent, NgxPrettyRadioGroupDirective, NgxPrettyRadioWillChangeComponent, NgxPrettySvgDirective, NgxPrettyToggleComponent, NgxPrettyToggleWillChangeComponent, PrettyCheckBoxToggleType, PrettyCheckboxAnimation, PrettyCheckboxColor, PrettyCheckboxShape, PrettyCheckboxStroke };
