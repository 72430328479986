import { ChangeDetectorRef, Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

import { TranslatePipe, TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-mailto',
  templateUrl: './mailto.component.html',
  styleUrls: ['./mailto.component.scss'],
  animations: [
    trigger('show', [
      state('in', style({ opacity: '1' })),
      transition('void => *', [
        style({ opacity: '0' }),
        animate(300),
      ]),
      transition('* => void', [
        animate(300, style({ opacity: '0' })),
      ]),
    ]),
  ],
  standalone: false,
})
export class MailtoComponent implements OnInit {

  @Output() onClose = new EventEmitter();

  @Input() email = '';
  message = '';
  subject = '';

  constructor(private translateService: TranslateService, private cdRef: ChangeDetectorRef) {
    this.subject = new TranslatePipe(this.translateService, this.cdRef).transform('publicProfile.messageSubject');
  }

  @HostBinding('@show') get() {
    return;
  }


  ngOnInit() {
  }


  close() {
    this.onClose.emit(true);
  }

  send() {

  }

}
