import * as i0 from '@angular/core';
import { InjectionToken, inject, Optional, Inject, Injectable, NgModule, SkipSelf } from '@angular/core';
import * as i2 from '@ngx-matomo/tracker';
import { INTERNAL_MATOMO_CONFIGURATION } from '@ngx-matomo/tracker';
import * as i1 from '@angular/router';
import { NavigationEnd, PRIMARY_OUTLET } from '@angular/router';
import { of, identity, forkJoin, from, combineLatest } from 'rxjs';
import { delay, filter, distinctUntilKeyChanged, switchMap, map, concatMap, tap, take, defaultIfEmpty, mapTo } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { __decorate, __param } from 'tslib';
import { APP_BASE_HREF, PlatformLocation } from '@angular/common';
const MATOMO_ROUTER_CONFIGURATION = new InjectionToken('MATOMO_ROUTER_CONFIGURATION');
const DEFAULT_ROUTER_CONFIGURATION = {
  prependBaseHref: true,
  trackPageTitle: true,
  delay: 0,
  exclude: []
};
const INTERNAL_ROUTER_CONFIGURATION = new InjectionToken('INTERNAL_ROUTER_CONFIGURATION', {
  factory: () => {
    const {
      disabled,
      enableLinkTracking
    } = inject(INTERNAL_MATOMO_CONFIGURATION);
    const routerConfig = inject(MATOMO_ROUTER_CONFIGURATION, {
      optional: true
    }) || {};
    return {
      ...DEFAULT_ROUTER_CONFIGURATION,
      ...routerConfig,
      enableLinkTracking,
      disabled
    };
  }
});
const MATOMO_ROUTER_INTERCEPTORS = new InjectionToken('MATOMO_ROUTER_INTERCEPTORS');
function provideInterceptor(type) {
  return {
    provide: MATOMO_ROUTER_INTERCEPTORS,
    multi: true,
    useClass: type
  };
}
function provideInterceptors(types) {
  if (!types) {
    return [];
  }
  return types.map(provideInterceptor);
}
function invalidInterceptorsProviderError() {
  return new Error('An invalid MATOMO_ROUTER_INTERCEPTORS provider was configured. Did you forget to set "multi: true" ?');
}

/**
 * @deprecated Use an interceptor calling `setDocumentTitle()` instead
 * @see MatomoRouterInterceptor
 * @see MATOMO_ROUTER_INTERCEPTORS
 */
const MATOMO_PAGE_TITLE_PROVIDER = new InjectionToken('MATOMO_PAGE_TITLE_PROVIDER', {
  factory: () => new DefaultPageTitleProvider(inject(Title))
});
class DefaultPageTitleProvider {
  constructor(title) {
    this.title = title;
  }
  getCurrentPageTitle(event) {
    return of(this.title.getTitle());
  }
}
const MATOMO_PAGE_URL_PROVIDER = new InjectionToken('MATOMO_PAGE_URL_PROVIDER', {
  factory: () => new DefaultPageUrlProvider(inject(INTERNAL_ROUTER_CONFIGURATION), inject(APP_BASE_HREF, {
    optional: true
  }), inject(PlatformLocation))
});
function trimTrailingSlash(str) {
  return str.endsWith('/') ? str.slice(0, -1) : str;
}
let DefaultPageUrlProvider = class DefaultPageUrlProvider {
  constructor(config, baseHref, platformLocation) {
    this.config = config;
    this.baseHref = baseHref;
    this.platformLocation = platformLocation;
  }
  getCurrentPageUrl(event) {
    const url = this.config.prependBaseHref ? this.getBaseHrefWithoutTrailingSlash() + event.urlAfterRedirects : event.urlAfterRedirects;
    return of(url);
  }
  getBaseHrefWithoutTrailingSlash() {
    return trimTrailingSlash(this.baseHref ?? this.platformLocation.getBaseHrefFromDOM());
  }
};
DefaultPageUrlProvider = __decorate([__param(0, Optional()), __param(0, Inject(INTERNAL_ROUTER_CONFIGURATION)), __param(1, Optional()), __param(1, Inject(APP_BASE_HREF))], DefaultPageUrlProvider);
function isNavigationEnd(event) {
  return event instanceof NavigationEnd;
}
function coerceRegExp(input) {
  return typeof input === 'string' ? new RegExp(input) : input;
}
function coerceRegExpArray(input) {
  if (!input) {
    return [];
  }
  return Array.isArray(input) ? input.map(coerceRegExp) : [coerceRegExp(input)];
}
function isNotExcluded(excludeConfig) {
  const exclusions = coerceRegExpArray(excludeConfig);
  return event => !exclusions.some(rx => event.urlAfterRedirects.match(rx));
}
class MatomoRouter {
  constructor(router, config, pageTitleProvider, pageUrlProvider, tracker, interceptors) {
    this.router = router;
    this.config = config;
    this.pageTitleProvider = pageTitleProvider;
    this.pageUrlProvider = pageUrlProvider;
    this.tracker = tracker;
    this.interceptors = interceptors;
    if (interceptors && !Array.isArray(interceptors)) {
      throw invalidInterceptorsProviderError();
    }
  }
  init() {
    if (this.config.disabled) {
      // Do not set-up router if globally disabled
      return;
    }
    const delayOp = this.config.delay === -1 ? identity : delay(this.config.delay);
    this.router.events.pipe(
    // Take only NavigationEnd events
    filter(isNavigationEnd),
    // Filter out excluded urls
    filter(isNotExcluded(this.config.exclude)),
    // Distinct urls
    distinctUntilKeyChanged('urlAfterRedirects'),
    // Optionally add some delay
    delayOp,
    // Set default page title & url
    switchMap(event => this.presetPageTitleAndUrl(event).pipe(map(({
      pageUrl
    }) => ({
      pageUrl,
      event
    })))),
    // Run interceptors then track page view
    concatMap(({
      event,
      pageUrl
    }) => this.callInterceptors(event).pipe(tap(() => this.trackPageView(pageUrl))))).subscribe();
  }
  callInterceptors(event) {
    if (this.interceptors) {
      return forkJoin(this.interceptors.map(interceptor => {
        const result = interceptor.beforePageTrack(event);
        const result$ = result == null ? of(undefined) : from(result);
        // Must not be an empty observable (otherwise forkJoin would complete without waiting others)
        return result$.pipe(take(1), defaultIfEmpty(undefined));
      })).pipe(mapTo(undefined), defaultIfEmpty(undefined));
    } else {
      return of(undefined);
    }
  }
  presetPageTitleAndUrl(event) {
    const title$ = this.config.trackPageTitle ? this.pageTitleProvider.getCurrentPageTitle(event).pipe(tap(pageTitle => this.tracker.setDocumentTitle(pageTitle))) : of(undefined);
    const url$ = this.pageUrlProvider.getCurrentPageUrl(event).pipe(tap(pageUrl => this.tracker.setCustomUrl(pageUrl)));
    return combineLatest([title$, url$]).pipe(map(([_, pageUrl]) => ({
      pageUrl
    })));
  }
  trackPageView(pageUrl) {
    this.tracker.trackPageView();
    if (this.config.enableLinkTracking) {
      this.tracker.enableLinkTracking(true);
    }
    // Set referrer for next page view
    this.tracker.setReferrerUrl(pageUrl);
  }
}
MatomoRouter.ɵfac = function MatomoRouter_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || MatomoRouter)(i0.ɵɵinject(i1.Router), i0.ɵɵinject(INTERNAL_ROUTER_CONFIGURATION), i0.ɵɵinject(MATOMO_PAGE_TITLE_PROVIDER), i0.ɵɵinject(MATOMO_PAGE_URL_PROVIDER), i0.ɵɵinject(i2.MatomoTracker), i0.ɵɵinject(MATOMO_ROUTER_INTERCEPTORS, 8));
};
MatomoRouter.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: MatomoRouter,
  factory: MatomoRouter.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatomoRouter, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: i1.Router
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [INTERNAL_ROUTER_CONFIGURATION]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [MATOMO_PAGE_TITLE_PROVIDER]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [MATOMO_PAGE_URL_PROVIDER]
      }]
    }, {
      type: i2.MatomoTracker
    }, {
      type: undefined,
      decorators: [{
        type: Optional
      }, {
        type: Inject,
        args: [MATOMO_ROUTER_INTERCEPTORS]
      }]
    }];
  }, null);
})();
class NgxMatomoRouterModule {
  constructor(router, parent) {
    this.router = router;
    if (!parent) {
      // Do not initialize if it is already (by a parent module)
      this.router.init();
    }
  }
  static forRoot({
    interceptors,
    ...config
  } = {}) {
    return {
      ngModule: NgxMatomoRouterModule,
      providers: [{
        provide: MATOMO_ROUTER_CONFIGURATION,
        useValue: config
      }, provideInterceptors(interceptors)]
    };
  }
}
NgxMatomoRouterModule.ɵfac = function NgxMatomoRouterModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || NgxMatomoRouterModule)(i0.ɵɵinject(MatomoRouter), i0.ɵɵinject(NgxMatomoRouterModule, 12));
};
NgxMatomoRouterModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: NgxMatomoRouterModule
});
NgxMatomoRouterModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxMatomoRouterModule, [{
    type: NgModule
  }], function () {
    return [{
      type: MatomoRouter
    }, {
      type: NgxMatomoRouterModule,
      decorators: [{
        type: Optional
      }, {
        type: SkipSelf
      }]
    }];
  }, null);
})();
function findChildRoute(route, outlet) {
  return route.children.find(child => child.outlet === outlet);
}
function getLeafRoute(route, outlet) {
  const child = findChildRoute(route, outlet);
  return child ? getLeafRoute(child, outlet) : route;
}

/**
 * Simple interceptor base looking into route's data for tracking
 *
 * @see MatomoRouteDataInterceptor
 */
class MatomoRouteInterceptorBase {
  constructor(router) {
    this.router = router;
  }
  beforePageTrack(event) {
    const route = this.getRoute(event);
    const data = this.extractRouteData(route);
    return this.processRouteData(data);
  }
  getRoute(event) {
    return getLeafRoute(this.router.routerState.snapshot.root, PRIMARY_OUTLET);
  }
}
const DEFAULT_DATA_KEY = 'matomo';
/**
 * Simple interceptor looking at 'matomo' key of route's data for tracking.
 *
 * It is possible to extend this class or {@link MatomoRouteInterceptorBase}
 * for custom behavior (to use another data key, etc.)
 *
 * @example
 * // Using provided MatomoRouteDataInterceptor (looks into 'matomo' data key)
 * const routes: Routes = [
 *   {
 *     path: '/hello',
 *     component: HelloComponent,
 *     data: {
 *       matomo: {
 *         title: 'Page title',
 *       } as MatomoRouteData
 *     }
 *   },
 * ];
 *
 * NgxMatomoRouterModule.forRoot({
 *   interceptors: [MatomoRouteDataInterceptor],
 * }),
 *
 * @example
 * // Using custom 'myCustomAnalyticsKey' data key
 * const routes: Routes = [
 *   {
 *     path: '/hello',
 *     component: HelloComponent,
 *     data: {
 *       myCustomAnalyticsKey: {
 *         title: 'Page title',
 *       } as MatomoRouteData
 *     }
 *   },
 * ];
 *
 * @Injectable()
 * export class MyCustomInterceptor extends MatomoRouteDataInterceptor {
 *   readonly dataKey = 'myCustomAnalyticsKey';
 * }
 *
 * NgxMatomoRouterModule.forRoot({
 *   interceptors: [MyCustomInterceptor],
 * }),
 *
 * @see MatomoRouteInterceptorBase
 */
class MatomoRouteDataInterceptor extends MatomoRouteInterceptorBase {
  constructor(tracker, router) {
    super(router);
    this.tracker = tracker;
    this.dataKey = DEFAULT_DATA_KEY;
  }
  extractRouteData(route) {
    return route.data[this.dataKey];
  }
  processRouteData(data) {
    if (!data) {
      return;
    }
    if (data.title) {
      this.tracker.setDocumentTitle(data.title);
    }
    if (data.ecommerce) {
      this.tracker.setEcommerceView(data.ecommerce);
    }
  }
}
MatomoRouteDataInterceptor.ɵfac = function MatomoRouteDataInterceptor_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || MatomoRouteDataInterceptor)(i0.ɵɵinject(i2.MatomoTracker), i0.ɵɵinject(i1.Router));
};
MatomoRouteDataInterceptor.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: MatomoRouteDataInterceptor,
  factory: MatomoRouteDataInterceptor.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatomoRouteDataInterceptor, [{
    type: Injectable
  }], function () {
    return [{
      type: i2.MatomoTracker
    }, {
      type: i1.Router
    }];
  }, null);
})();

/*
 * Public API Surface of router
 */

/**
 * Generated bundle index. Do not edit.
 */

export { MATOMO_PAGE_TITLE_PROVIDER, MATOMO_PAGE_URL_PROVIDER, MATOMO_ROUTER_CONFIGURATION, MATOMO_ROUTER_INTERCEPTORS, MatomoRouteDataInterceptor, MatomoRouteInterceptorBase, NgxMatomoRouterModule, provideInterceptor, provideInterceptors };
