<div class="c-popup">
  <div class="c-header">
    {{'publicProfile.editorTitle' | translate}}
  </div>
  <div class="c-body">
    <textarea [(ngModel)]="message" class="form-control c-message-input"
      placeholder="{{'publicProfile.messagePlaceholder' | translate}}" rows="auto">
    </textarea>
  </div>
  <div class="c-footer">
    <button (click)="close()" class="btn btn-dark g-btn-bold c-btn">{{'publicProfile.buttons.cancel' |
      translate}}</button>
    <a [href]="'mailto:'+email+'?subject='+subject+'&body='+message"
      class="btn btn-primary g-btn-bold c-btn custom-btn-primary">
      {{'publicProfile.buttons.done' | translate}}
    </a>
  </div>

</div>