export const environment = {
  name: 'development',
  production: false,
  shortlinkUrl: 'https://dev.complero.info/s/',
  hostname: 'https://dashboard.dev.complero.info/e',
  textsFolder: '/assets/texts/',
  sentryDSM: 'https://b2cb47cb045f46a48aa0fb59dfdf9925@glitchtip.infra.complero.info/1',
  appointmentUrl: 'https://datenwaechter.typeform.com/to/gPce4Nl2',
  version: 'DEV.25.4.0',
  sentryPopup: true,
  matomoSiteID: 1,
  shortRequestTimeout: 7000,
  longRequestTimeout: 10000,
};
